import { ref, onMounted } from 'vue'

export default function getWindowSize () {
  const windowWidth = ref(0)
  const updateWindowSize = () => {
    windowWidth.value = window.innerWidth
  }
  onMounted(() => {
    updateWindowSize()
    window.addEventListener('resize', updateWindowSize)
  })
  return windowWidth
}

<template>
  <div
    :class="{
      'text-malahierba-blue': item.urlAudio,
      'text-malahierba-green': item.urlVideo
    }"
    class="font-JetBrains"
  >
    <p class="font-medium tracking-normal text-xl xs:text-2xl md:text-xl">{</p>
    <p class="font-light tracking-normal text-2xl xs:text-3xl -mt-1 xs:mt-1 md:text-2xl md:-mt-1">
      #{{ parseInt(item.id) }}
    </p>
    <p class="font-black tracking-normal text-xl xs:text-2xl leading-5 xs:leading-7 xs:mt-1 md:text-xl md:leading-5 sm:-mt-1">
      {{ item.artistDrawer }}
    </p>
    <p class="font-medium italic tracking-tighter text-lg xs:text-xl leading-5 xs:leading-6 mt-1 md:text-lg md:leading-5">
      {{ item.placeDrawer }}
    </p>
    <p class="font-medium text-xs xs:text-sm mt-1">
      {{ item.location[0].toFixed(5)}},{{item.location[1].toFixed(5) }}
    </p>
    <p class="font-medium tracking-normal text-xl xs:text-2xl md:text-xl">}</p>
  </div>
</template>

<script>
export default {
  name: 'content-post-brief-card',
  props: ['item']
}
</script>

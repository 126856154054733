import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import cdnImages from '../variables'

export default function selectedData () {
  const store = useStore()
  const route = useRoute()
  const currentId = computed(() => store.state.id)
  const information = computed(() => store.state.information)
  const { id } = route.params
  store.dispatch('currentId', parseInt(id))
  if (!isNaN(currentId)) {
    information.value.map(info => {
      const newNameImage = `${cdnImages.cdnImages}${info.urlImage}`
      info.imgCdn = newNameImage
    })
  }
  const currentInfo = information.value.find(info => info.id === currentId.value)
  return currentInfo
}

<template>
  <div>
    <aside class="navigation absolute top-0 z-9995">
      <div class="flex flex-col w-10 h-72 xs:h-full relative top-7 z-9995">
        <!-- This input tag trick for simulate hamburguer menu -->
        <input
          @click="openMenu"
          type="checkbox"
          class="navigation-checkbox"
          id="navi-toggle"
        >
        <transition name="hamburguerToX">
          <label for="navi-toggle" class="navigation-button absolute flex text-center cursor-pointer w-8 h-8 -top-5 sm:-top-3"
            style="left:13px"
          >
            <span class="navigation-icon w-full bg-white inline-block relative border-malahierba">&nbsp;</span>
          </label>
        </transition>
        <span
          style="transform: rotate(-90deg);"
          class="font-JetBrains text-xl subpixel-antialiased text-malahierba-blue link cursor-pointer bg-white w-24 text-center border-malahierba mt-20 mb-2 -ml-5"
          @click.prevent="setRandomView()"
        >
          random
        </span>
        <router-link
          style="transform: rotate(-90deg)"
          to="/info"
          class="font-JetBrains text-xl subpixel-antialiased text-malahierba-blue link cursor-pointer bg-white w-20 text-center border-malahierba mt-16 -ml-3"
        >
          info
        </router-link>
        <a
          href="https://www.instagram.com/malahierba.tropical"
          class="navigation-instagram w-8 h-8 relative ml-1 top-10"
          target="_blank"
          style="transform: rotate(-90deg);left:8px;"
        >
          <img
            :src="instagram"
            class="cursor-pointer"
            alt="instagram_icon"
          />
        </a>
      </div>
    </aside>
    <div class="absolute z-9995 w-10 h-22 bottom-6">
      <!-- Control zoom buttons -->
      <div
        v-if="$route.path !== '/info'"
        class="hidden xs:block relative z-999 w-8 h-26 top-0 left-5"
        style="margin-left:-7px;"
      >
        <div class="h-26 flex flex-col bg-white border-malahierba"
          style="width:30px;"
        >
          <button
            type="button"
            class="px-1 py-1 flex justify-center items-center"
            @click="zoomIn"
          >
            <svg class="w-5 h-5 dark:text-white" fill="none" stroke="#4417f5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
            </svg>
          </button>
          <button
            type="button"
            class="px-1 py-2 flex justify-center items-center"
            @click="setCenter()"
            style="border-top:1px solid white;border-bottom:1px solid white;"
          >
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#4417f5">
              <path d="M511.8 287.6L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L416 100.7V64C416 46.33 430.3 32 448 32H480C497.7 32 512 46.33 512 64V185L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6L511.8 287.6z"/>
            </svg>
          </button>
          <button
            type="button"
            class="px-1 py-1 flex justify-center items-center"
            @click="zoomOut"
          >
            <svg class="w-4 h-5" fill="none" stroke="#4417f5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M20 12H4"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import instagram from '../assets/instagram.svg'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, watch, ref } from 'vue'
export default {
  name: 'navigation-view',
  props: ['openMenu'],
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const lastCliked = ref(0)
    const currentWidthScreen = computed(() => store.state.currentWidthScreen)
    const drawer = computed(() => store.state.drawer)
    const information = computed(() => store.state.information)
    const map = computed(() => store.state.map)
    const setRandomView = () => {
      // This code waits between click and click to avoid a loop
      // that redirect between the previous point and the current point
      const timeNow = new Date().getTime()
      if (timeNow > (lastCliked.value + 2000)) {
        const randomNumber = parseInt(Math.random() * information.value.length)
        router.push({ path: `/${randomNumber}` })
        // If the drawer open, close it
        if (drawer.value.active) {
          // Generate phantom click for icon drawer change to open icon
          document.querySelector('#navi-toggle').click()
        }
      } else {
        store.dispatch('setWait', true)
        setTimeout(() => store.dispatch('setWait', false), 2000)
        // console.log('wait', lastCliked.value)
      }
      lastCliked.value = timeNow
    }
    const zoomIn = () => store.dispatch('zoomIn')
    const zoomOut = () => store.dispatch('zoomOut')
    const setCenter = () => {
      currentWidthScreen.value > 500
        ? store.dispatch('setCenter', 2)
        : store.dispatch('setCenter', 1)
    }
    watch(() => route.params, function (from) {
      const { id } = from
      if ((route.fullPath === '/') && (!id)) {
        setCenter()
      }
    })
    return { instagram, drawer, information, map, setRandomView, zoomIn, zoomOut, setCenter }
  }
}
</script>

<template>
  <div
    :class="{
      'h-minim z-9995': !show,
      'h-full z-9995 justify-start': show
    }"
    class="border-modal-audio flex flex-col p-3 xss:p-4 xs:p-5"
    v-if="selectedData() && selectedData().urlVideo"
  >
    <div class="relative w-full overflow-hidden"
      style="padding-top:56.25%;"
    >
      <iframe
        :id="`iframemh-${selectedData().id}`"
        class="absolute top-0 left-0 bottom-0 right-0 w-full h-full border-0"
        :src="selectedData().urlVideo"
        allowfullscreen
      ></iframe>
    </div>
    <!-- Content celular -->
    <div class="block xs:hidden">
      <div class="flex">
        <div class="text-malahierba-blue
          w-full
          font-JetBrains
          text-left
          mt-4 mb-2
          "
        >
          <p class="text-xl leading-5 font-black">
            {{ !show ? selectedData().placeDrawer : selectedData().artistDrawer}}
          </p>
          <p class="text-sm leading-1 font-normal italic">
            {{ !show ? selectedData().artistDrawer : selectedData().placeDrawer }}
          </p>
          <p class="text-xss leading-5 -mt-1 font-light">
            {{selectedData().location[0].toFixed(5)}},{{selectedData().location[1].toFixed(5)}}
          </p>
        </div>
        <div class="flex justify-end items-start xs:hidden">
          <button
            type="button"
            class="mt-2"
            @click="show = !show"
          >
            <svg v-if="!show" class="w-6 h-6 dark:text-white" fill="none" stroke="#4417f5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
            </svg>
            <svg v-else class="w-6 h-6" fill="none" stroke="#4417f5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M20 12H4"></path>
            </svg>
          </button>
        </div>
      </div>
      <!-- Mobile description -->
      <transition name="slidemh">
        <div class="block xs:hidden" v-if="show">
          <p
            class="text-malahierba-blue
            font-JetBrains font-normal
            text-sm
            text-left
            leading-1
            h-56 xss:h-22 xs:h-56
            overflow-y-auto overflow-x-hidden"
          >
            {{selectedData().description}}
          </p>
          <a
            v-if="selectedData() && selectedData().upload && show"
            target="_blank"
            class="block italic text-black font-JetBrains font-medium text-left text-xs tracking-normal lowercase my-2"
            :href="selectedData().upload.src"
          >
            ::---- {{selectedData() && selectedData().upload ? selectedData().upload.title : ''}}
          </a>
        </div>
      </transition>
    </div>
    <!-- Content desktop -->
    <div class="hidden xs:block">
      <div class="text-malahierba-blue tracking-tighter font-JetBrains text-left mt-2 md:mt-3">
        <p class="text-xl leading-5 font-black">
          {{selectedData().artistDrawer}}
        </p>
        <p class="text-sm leading-1 font-normal italic">
          {{selectedData().placeDrawer}}
        </p>
        <p class="text-xss leading-5 -mt-1 font-light">
          {{selectedData().location[0].toFixed(5)}},{{selectedData().location[1].toFixed(5)}}
        </p>
        <p class="wrap-desc-video-desktop">
          {{selectedData().description}}
        </p>
        <a
          v-if="selectedData() && selectedData().upload"
          target="_blank"
          class="block italic text-black font-JetBrains font-normal text-left text-xs tracking-normal lowercase mt-4"
          :href="selectedData().upload.src"
        >
          ::---- {{selectedData() && selectedData().upload ? selectedData().upload.title : ''}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import selectedData from '../../composables/selectedData'
import hidePopupSystem from '../../composables/hidePopupSystem'
export default {
  name: 'mobil-modal-video',
  setup () {
    const show = ref(false)
    hidePopupSystem()
    return { selectedData, show }
  },
  watch: {
    show (value) {
      this.$emit('changeHeightModal', value)
    }
  }
}
</script>

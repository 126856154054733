<template>
  <app-teleport />
  <router-view></router-view>
</template>

<script>
import { onMounted } from 'vue'
import ContentTeleport from './components/ContentTeleport.vue'
export default {
  name: 'app-view',
  components: {
    'app-teleport': ContentTeleport
  },
  setup () {
    onMounted(() => {
      // Prevent open popup window when reload page
      window.onbeforeunload = function () {
        window.onbeforeunload = null
      }
    })
  }
}
</script>

const cdnImages = 'https://malahierbatropical.com/images/radio/'
// const urlMalaHierba = 'https://malahierbatropical.com/data/data.json'
const urlMalaHierba = 'https://mh.parallelo.ai/p'
const mapBoxSkin = 'https://api.mapbox.com/styles/v1/malahierba/cjlbfarsj5d4k2smenuvqzrwo/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFsYWhpZXJiYSIsImEiOiJjamxiY2FsM3IxOWlnM3huNm42Zm5qd2t0In0.jlnFRW6UUPvWAxigw9aTPg'

export default {
  cdnImages,
  urlMalaHierba,
  mapBoxSkin
}

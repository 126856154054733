<template>
  <div class="wrap-content-drawer">
    <div class="w-full h-full pl-16 pt-2">
        <div class="relative w-97% mt-6 pb-4 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-6 gap-x-1">
          <PostBriefCard />
        </div>
    </div>
  </div>
</template>

<script>
import PostBriefCard from '../components/PostBriefCard.vue'
export default {
  name: 'content-drawer-view',
  components: {
    PostBriefCard
  }
}
</script>

import { createStore } from 'vuex'
import axios from 'axios'
import globals from '../variables.js'
import router from './../router'
import { ref } from 'vue'

const { cdnImages, urlMalaHierba } = globals
const centerMap = window.innerWidth < 500 ? 1 : 2

export default createStore({
  state: {
    zoom: centerMap,
    information: [],
    lat: 101.89,
    lng: 0,
    drawer: {},
    map: {},
    iframeLoad: false,
    portal: false,
    id: NaN,
    iconSize: 15,
    fetchData: false,
    currentWidthScreen: undefined,
    wait: false
  },
  getters: {
    informationTransform: state => {
      const informationCopy = [...state.information]
      informationCopy.map(info => {
        const counter = ref(0)
        const newNameImage = `${cdnImages}${info.urlImage}`
        info.imgCdn = newNameImage
        info.counter = counter
      })
      const reverse = informationCopy.reverse()
      return reverse
    }
  },
  mutations: {
    information (state, information) {
      state.information = information
    },
    drawer (state, drawer) {
      state.drawer = drawer
    },
    map (state, map) {
      state.map = map
    },
    lat (state, lat) {
      state.lat = lat
    },
    lng (state, lng) {
      state.lng = lng
    },
    iframeLoad (state, iframe) {
      state.iframeLoad = iframe
    },
    portal (state, portal) {
      state.portal = portal
    },
    id (state, id) {
      state.id = id
    },
    zoom (state, zoom) {
      state.zoom = zoom
    },
    currentWidthScreen (state, width) {
      state.currentWidthScreen = width
    },
    fetchData (state, data) {
      state.fetchData = data
    },
    wait (state, wait) {
      state.wait = wait
    }
  },
  actions: {
    async loadInformation ({ commit }) {
      try {
        const res = await axios.get(urlMalaHierba)
        if (res.status !== 200) {
          commit('fetchData', false)
          throw Error('No data available')
        }
        commit('fetchData', true)
        commit('information', res.data)
      } catch (e) {
        commit('fetchData', false)
        console.log('error load data information', e)
      }
    },
    zoomIn ({ state }) {
      state.map.leafletObject.setZoom(state.map.leafletObject.getZoom() + 1)
    },
    zoomOut ({ state }) {
      state.map.leafletObject.setZoom(state.map.leafletObject.getZoom() - 1)
    },
    setCenter ({ commit, dispatch }, zoom) {
      const coords = [101.89, 0]
      dispatch('moveCamera', coords)
      dispatch('currentZoom', zoom)
      commit('lat', coords[0])
      commit('lng', coords[1])
    },
    moveCamera ({ commit }, coords) {
      commit('lat', coords[0])
      commit('lng', coords[1])
    },
    setDrawer ({ commit }, drawer) {
      commit('drawer', drawer)
    },
    setMap ({ commit }, map) {
      commit('map', map)
    },
    setLat ({ commit }, lat) {
      commit('lat', lat)
    },
    setLng ({ commit }, lng) {
      commit('lng', lng)
    },
    iframeLoaded ({ commit }, val) {
      commit('iframeLoad', val)
    },
    setPortal ({ commit }, val) {
      commit('portal', val)
    },
    currentId ({ commit }, id) {
      commit('id', id)
    },
    currentZoom ({ commit }, zoom) {
      commit('zoom', zoom)
    },
    setScreen ({ commit }, width) {
      commit('currentWidthScreen', width)
    },
    updateMap ({ dispatch, state }, id) {
      dispatch('currentZoom', 15)
      setTimeout(() => {
        const selectedInfo = state.information && state.information.find(info => info.id === id)
        if (selectedInfo) {
          const coords = [selectedInfo.location[0], selectedInfo.location[1]]
          dispatch('moveCamera', coords)
        } else {
          router.push('/')
          dispatch('currentZoom', 2)
        }
      }, 600)
    },
    setWait ({ commit }, wait) {
      commit('wait', wait)
    }
  },
  modules: {
  }
})

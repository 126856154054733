<template>
  <l-map
    class="relative"
    v-model="zoom"
    v-model:zoom="zoom"
    ref="map"
    :center="[lat, lng]"
    :zoomAnimation="true"
    :zoomDelta="1"
    :maxZoom="22"
    @ready="mapReady()"
    :options="{
      zoomControl:false,
      attributionControl: false
    }"
  >
    <!-- Buttons for testing purposes -->
    <!-- Change hidden class for absolute for show buttons: no delete -->
    <div class="hidden top-3/4 right-2/3 z-999">
      <button class="bg-green-500 px-2 py-2" @click="reinitIframe()">Close Modal</button>
      <button class="bg-blue-200 px-2 py-2" @click="$store.dispatch('setPortal', true)">Open Modal</button>
    </div>
    <l-tile-layer
      :url="mapBoxSkin"
      layer-type="base"
      name="mapBox"
    ></l-tile-layer>
    <l-marker
      v-for="point of informationTransform"
      :key="`marker-${point.id}`"
      :lat-lng="point.location"
      :options="{ className: `marker-${point.id}`}"
      @click="$router.push({ path: `/${point.id}`})"
    >
      <l-icon
        :icon-size="[iconSize, iconSize]"
        :class-name="`w-3.5 h-3.5 icon-${point.id}`"
        :icon-url="require(`@/assets/images/marker.png`)"
      />
      <l-tooltip
        :options="{
          direction: windowWidth >= 425 ? 'left' : 'bottom'
      }">
        <p class="font-JetBrains text-sm sm:text-xl px-2 sm:px-4 font-extrabold tracking-normal">
          {{point.artistDrawer}}
        </p>
        <p class="font-JetBrains text-xs sm:text-sm font-normal tracking-tighter px-2 sm:px-4 italic">
          {{point.placeDrawer}}
        </p>
      </l-tooltip>
    </l-marker>
  </l-map>
</template>

<script>
import getWindowSize from '../composables/getWindowSize'
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon
} from '@vue-leaflet/vue-leaflet'
import 'leaflet/dist/leaflet.css'
import { useStore, mapGetters } from 'vuex'
import { computed, watch, toRaw, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import globals from '../variables.js'
const { mapBoxSkin } = globals

export default {
  name: 'map-view',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon
  },
  setup () {
    const map = ref(null)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const zoom = computed({
      get: () => store.state.zoom,
      set: val => store.dispatch('currentZoom', val)
    })
    const iconSize = computed(() => store.state.iconSize)
    const lat = computed(() => store.state.lat)
    const lng = computed(() => store.state.lng)
    const portal = computed(() => store.state.portal)
    const currentWidthScreen = computed(() => store.state.currentWidthScreen)
    const reinitIframe = () => store.dispatch('setPortal', false)
    const prevOrCurrentMarker = (markers, id) => {
      return Object.entries(markers).find(
        marker => marker[1].options.className === `marker-${id}`
      )
    }
    const openTooltipLeaflet = (markers, id) => {
      const currentMarker = prevOrCurrentMarker(markers, id)
      if (currentWidthScreen.value > 500) {
        currentMarker && currentMarker[1].toggleTooltip() // Open tooltip for desktop devices
      }
      currentMarker && currentMarker[1].openTooltip() // Open tooltip for mobile devices
    }
    const mapReady = () => {
      store.dispatch('setMap', map.value)
      // Limit the visible area of a leaflet map
      map.value.leafletObject.setMaxBounds([[-113.8299, 180.345], [113.8299, -180.345]])
      const { id } = route.params
      if (id) {
        // Open teleport first Time
        store.dispatch('setPortal', true)
        store.dispatch('currentId', parseInt(id))
        router.push({ path: `/${id}` })
        store.dispatch('updateMap', parseInt(id))
        // Open tooltip first Time
        setTimeout(() => {
          // toRaw return a raw proxy, not a reactive copy
          const markers = toRaw(map.value.leafletObject._layers)
          openTooltipLeaflet(markers, id)
        }, 1000)
      }
    }
    const windowWidth = getWindowSize()
    watch(() => portal.value, function () {
      portal.value
        ? setTimeout(() => store.dispatch('iframeLoaded', true), 1000)
        : setTimeout(() => store.dispatch('iframeLoaded', false), 1000)
    })
    watch(() => route.params, function (from, to) {
      store.dispatch('setPortal', false)
      // toRaw return a raw proxy, not a reactive copy
      const markers = toRaw(map.value.leafletObject._layers)
      const prevSelectMarker = prevOrCurrentMarker(markers, to.id)
      prevSelectMarker && prevSelectMarker[1].closeTooltip()
      if (from.id) {
        store.dispatch('updateMap', parseInt(from.id))
        setTimeout(() => openTooltipLeaflet(markers, from.id), 1000)
      }
      setTimeout(() => store.dispatch('setPortal', true), 1500)
    })
    return {
      zoom,
      lat,
      lng,
      iconSize,
      mapBoxSkin,
      reinitIframe,
      mapReady,
      map,
      windowWidth
    }
  },
  // https://vuex.vuejs.org/guide/getters.html
  /* As of Vue 3.0, the getter's result is not cached as the computed property does.
  This is a known issue that requires Vue 3.1 to be released. You can learn more at PR #1878. */
  computed: {
    ...mapGetters(['informationTransform'])
  }
}
</script>

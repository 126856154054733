<template>
  <div class="bg-mala bg-no-repeat bg-center w-full fixed h-full cursor-default overflow-x-hidden overflow-scroll bg-malahierba-white flex justify-center items-center">
    <h1 class="text-center text-xl font-JetBrains font-extrabold bg-malahierba-green px-4 py-6">
      Esta página no existe
    </h1>
  </div>
</template>

<script>
export default {
  name: 'error-view',
  mounted () {
    setTimeout(() => this.$router.push({ path: '/' }), 2000)
  }
}
</script>

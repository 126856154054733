<template>
  <div
    class="sub-container relative cursor-pointer"
    v-for="item in informationTransform"
    :key="`disco-${item.name}`"
  >
    <div
      :class="{
        'bg-malahierba-green': item.urlAudio,
        'bg-malahierba-blue': item.urlVideo
      }"
    >
      <!-- This is for desktop -->
      <img
        :src="item.imgCdn"
        :alt="item.artistModal"
        class="hidden xs:block h-auto w-full relative ease-in-out duration-300"
        :class="item.urlAudio ? 'opacity-100 hover:opacity-0 z-10' : 'opacity-0 hover:opacity-100 z-20'"
        @click.prevent="closeDrawerRedirect(item)"
      />
      <!-- Content text tablet and desktop -->
      <div
        class="absolute
          container-drawer
          w-full h-full
          left-0 top-0
          hidden xs:flex flex-col justify-between
          p-3 xs:p-4 sm:p-3 xl:p-4 opacity-100 hover:opacity-0
        "
        @click.prevent="closeDrawerRedirect(item)"
      >
        <app-content-post-brief-card :item="item" />
      </div>
      <!-- this is for mobil -->
      <img
        :class="{
          'opacity-100 hover:opacity-0 z-10': item.urlAudio,
          'opacity-0 hover:opacity-100 z-20': item.urlVideo
        }"
        :src="item.imgCdn"
        :alt="item.artistModal"
        class="block xs:hidden h-auto w-full relative ease-in-out duration-300"
        @click.prevent="onClickImage(item)"
      />
      <!-- Content text mobil -->
      <div
        :class="{
          'opacity-100': item.counter === 1,
          'opacity-0': item.counter === 0
        }"
        class="absolute
          w-full h-full
          left-0 top-0
          flex xs:hidden flex-col justify-between
          p-3 xs:p-4 sm:p-3 xl:p-4 opacity-100 hover:opacity-0
        "
        @click.prevent="onClickImage(item)"
      >
        <app-content-post-brief-card :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentPostBriefCard from './ContentPostBriefCard.vue'
import { useStore, mapGetters } from 'vuex'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
export default {
  name: 'post-brief-view',
  components: {
    'app-content-post-brief-card': ContentPostBriefCard
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const drawer = computed(() => store.state.drawer)
    const closeDrawerRedirect = ({ id }) => {
      const close = setTimeout(() => drawer.value.close(), 500)
      clearTimeout(close)
      // Generate phantom click for icon drawer change to open icon
      store.dispatch('currentId', parseInt(id))
      document.querySelector('#navi-toggle').click()
      router.push({ path: `/${id}` })
    }
    const onClickImage = (item) => {
      item.counter = (item.counter + 1) % 3
      if (item.counter === 2) {
        closeDrawerRedirect(item)
        item.counter = 0
      }
    }
    return { closeDrawerRedirect, onClickImage }
  },
  // https://vuex.vuejs.org/guide/getters.html
  /* As of Vue 3.0, the getter's result is not cached as the computed property does.
  This is a known issue that requires Vue 3.1 to be released. You can learn more at PR #1878. */
  computed: {
    ...mapGetters(['informationTransform'])
  }
}
</script>

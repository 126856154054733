import { onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default function hidePopupSystem () {
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const id = computed(() => store.state.id)
  onMounted(() => {
    // Prevent open popup window when reload page
    const iframe = document.querySelector(`#iframemh-${id.value}`)
    window.addEventListener('beforeunload', function () {
      store.dispatch('setPortal', false)
      if (iframe && route.params !== '/info') {
        iframe.remove()
      }
      router.go()
      setTimeout(() => { router.go() }, 800)
    })
  })
}

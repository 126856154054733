import { ref } from 'vue'

export default function drawerHideClose () {
  const drawer = ref(null)
  const openMenu = () => {
    drawer.value.active
      ? drawer.value.close()
      : drawer.value.open()
  }
  return {
    openMenu,
    drawer
  }
}

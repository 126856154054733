<template>
  <div
    :class="{
      'h-minim': !show,
      'h-full z-9995': show
    }"
    :style="drawTopLine()"
    class="border-modal-audio flex flex-col justify-between overflow-y-auto sm:overflow-y-hidden"
    v-if="selectedData() && selectedData().urlAudio"
  >
    <!-- Content mobil and tablet -->
    <div
      class="xs:hidden"
      :class="{
        'px-3 pt-3 pb-0 xss:px-5 xss:pt-5 xs:pb-0': show,
        'p-3 xss:p-5': !show
      }"
    >
      <div
        :class="{
          'flex-col': show,
          'flex-row': !show
        }"
        class="flex w-full xs:hidden"
      >
        <img
          :class="{
            'h-60 object-cover object-top mt-2': show,
            'h-24 mr-3': !show
          }"
          class="w-auto h-28 xs:py-1 xs:pr-1"
          :src="selectedData().imgCdn"
          :alt="selectedData().placeModal"
        />
        <div
          class="text-malahierba-blue
            w-full
            text-xs xss:text-sm
            leading-12 xss:leading-none
            font-JetBrains
            tracking-tighter
            flex flex-col justify-end
          "
          v-if="!show"
        >
          <p class="font-bold tracking-wide mb-1 xs:hidden">
            {{selectedData().placeDrawer}}
          </p>
          <p class="font-normal tracking-2.9px xs:hidden">
            {{selectedData().artistDrawer}}
          </p>
          <p class="hidden xs:block font-black">
            {{selectedData().placeDrawer}}
          </p>
          <p class="hidden xs:block font-normal italic">
            {{selectedData().artistDrawer}}
          </p>
          <p class="hidden xs:block text-xss font-thin mt-1">
            {{selectedData().location[0].toFixed(3)}},{{selectedData().location[1].toFixed(3)}}
          </p>
        </div>
        <div
          v-else
          class="text-malahierba-blue font-JetBrains text-left mt-3"
        >
          <p class="text-xl font-black mb-1" style="line-height:21px;">
            {{selectedData().artistDrawer}}
          </p>
          <p class="text-sm font-normal italic" style="line-height:14px;">
            {{selectedData().placeDrawer}}
          </p>
          <p class="text-xss leading-6 font-light">
            {{selectedData().location[0].toFixed(5)}},{{selectedData().location[1].toFixed(5)}}
          </p>
        </div>
        <!-- button -->
        <div
          class="flex justify-end items-start xs:hidden"
          :class="{'order-first': show}"
        >
          <button
            type="button"
            @click="show = !show"
          >
            <svg v-if="!show" class="w-6 h-6 dark:text-white" fill="none" stroke="#4417f5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
            </svg>
            <svg v-else class="w-6 h-6" fill="none" stroke="#4417f5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M20 12H4"></path>
            </svg>
          </button>
        </div>
        <!-- Description -->
        <transition name="slidemh" appear>
          <div class="block xs:hidden mt-1" v-if="show">
            <p
              class="text-malahierba-blue
              font-JetBrains font-normal
              text-xs
              text-left
              leading-1
              h-44 xss:h-22 xs:h-56
              overflow-y-auto overflow-x-hidden"
              :class="{'mb-3': selectedData() && !selectedData().upload.title && show}"
            >
              {{selectedData().description}}
            </p>
            <a
              v-if="selectedData() && selectedData().upload && show"
              target="_blank"
              class="block italic text-black font-JetBrains font-medium text-left text-xs tracking-normal lowercase my-2"
              :href="selectedData().upload.src"
            >
              ::---- {{selectedData() && selectedData().upload ? selectedData().upload.title : ''}}
            </a>
          </div>
        </transition>
      </div>
    </div>
    <!-- Iframe -->
    <div class="block">
      <div class="w-auto h-12 overflow-hidden xss:h-10 mx-3 xss:mx-5 xs:mt-3 mb-1">
        <iframe
          :id="`iframemh-${selectedData().id}`"
          class="relative
          w-96 xss:w-screen xs:w-80
          h-16 -top-2 xss:-top-3
          left-ifm-mobil"
          :src="selectedData().urlAudio"
        ></iframe>
      </div>

    </div>
    <!-- Content desktop -->
    <div class="hidden xs:block relative overflow-hidden inside-modal">
      <div class="px-4 pb-4">
        <div class="block w-full bg-mala-white mt-2">
          <img
            class="w-full"
            :src="selectedData().imgCdn"
            :alt="selectedData().placeModal"
          />
          <div class="text-malahierba-blue font-JetBrains text-left mt-3">
            <p class="text-xl font-black mb-1" style="line-height:21px;">
              {{selectedData().artistDrawer}}
            </p>
            <p class="text-sm font-normal italic -mt-1" style="line-height:14px;">
              {{selectedData().placeDrawer}}
            </p>
            <p class="text-xss leading-5 font-light">
              {{selectedData().location[0].toFixed(5)}},{{selectedData().location[1].toFixed(5)}}
            </p>
          </div>
        </div>
      </div>
      <div class="overlay absolute top-0 bottom-0 right-0 left-0 w-full h-full opacity-0 bg-mala-white px-5 pt-3 transition easy-in-out cursor-default">
        <p class="text-malahierba-blue font-JetBrains font-normal text-xs leading-14 lg:leading-15 lg:text-sm pb-0 h-auto">
          {{selectedData().description}}
        </p>
        <div
          v-if="selectedData() && !selectedData().upload.title"
          class="flex justify-center relative mt-1 lg:mt-2"
        >
          <p>:</p>
        </div>
        <a
          v-if="selectedData() && selectedData().upload"
          target="_blank"
          class="block italic text-black font-JetBrains font-normal text-left text-xs tracking-normal lowercase mt-1 mb-2 lg:my-2 hover:bg-malahierba-blue hover:text-malahierba-green"
          :href="selectedData().upload.src"
        >
          ::---- {{selectedData() && selectedData().upload ? selectedData().upload.title : ''}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import selectedData from '../../composables/selectedData'
import hidePopupSystem from '../../composables/hidePopupSystem'
export default {
  name: 'modal-audio-view',
  setup () {
    const show = ref(false)
    hidePopupSystem()
    const drawTopLine = () => {
      return show.value
        ? 'border-top: 1px solid transparent;'
        : 'border-top: 1px solid #4417f5;'
    }
    return { selectedData, show, drawTopLine }
  },
  watch: {
    show (value) {
      this.$emit('changeHeightModal', value)
    }
  }
}
</script>

<template>
  <teleport to="#endofbody">
    <!-- Testing propuses class add to portal -->
    <!-- bg-green-500 xss:bg-red-500 xs:bg-yellow-500 sm:bg-purple-300 md:bg-green-300 lg:bg-yellow-500 xl:bg-blue-300 -->
    <div
      v-if="portal"
      :class="{
        'h-minim': !changeHeight || windowWidth >= 425,
        'h-full z-9995': changeHeight && windowWidth < 424
      }"
      class="modal
        fixed
        z-999 w-full xs:w-72 sm:w-sm-72 md:w-80
        bottom-0 xs:right-0 xs:top-4 sm:top-6
        bg-mala-white"
    >
      <app-modal-audio @changeHeightModal="changeHeightModal" />
      <app-modal-video @changeHeightModal="changeHeightModal" />
    </div>
  </teleport>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import getWindowSize from '../composables/getWindowSize'
import ModalAudio from '../components/Modal/ModalAudio.vue'
import ModalVideo from '../components/Modal/ModalVideo.vue'

export default {
  name: 'content-teleport',
  components: {
    'app-modal-audio': ModalAudio,
    'app-modal-video': ModalVideo
  },
  setup () {
    const store = useStore()
    const changeHeight = ref(false)
    const portal = computed(() => store.state.portal)
    const changeHeightModal = value => { changeHeight.value = value }
    const windowWidth = getWindowSize()
    return { portal, changeHeight, windowWidth, changeHeightModal }
  }
}
</script>

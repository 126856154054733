<template>
  <div class="w-full h-screen">
    <app-drawer
      :direction="'left'"
      :exist="true"
      ref="drawer"
    >
      <app-content-drawer />
    </app-drawer>
    <app-spinner v-if="!portal && $route.params.id || !fetchData" />
    <app-navigation-menu :openMenu="openMenu" />
    <transition name="opacitymh">
      <div
        v-if="wait"
        class="absolute z-999 w-auto bg-white left-14 top-16 mt-3 shadow-md"
      >
        <p class="mx-2 my-1 font-JetBrains tracking-tighter text-xs text-malahierba-blue font-bold">
          Por favor espera e<br>inténtalo de nuevo!
        </p>
      </div>
    </transition>
    <Map />
  </div>
</template>

<script>
import Map from '../components/Map.vue'
import NavigationMenu from '../components/NavigationMenu.vue'
import Drawer from '../components/Drawer.vue'
import ContentDrawer from '../components/ContentDrawer.vue'
import SpinnerTeleport from '../components/SpinnerTeleport.vue'
import { computed, onMounted } from 'vue'
import drawerHideClose from '../composables/drawerHideClose'
import { useStore } from 'vuex'
export default {
  name: 'main-view',
  components: {
    Map,
    'app-navigation-menu': NavigationMenu,
    'app-drawer': Drawer,
    'app-content-drawer': ContentDrawer,
    'app-spinner': SpinnerTeleport
  },
  setup () {
    const store = useStore()
    const portal = computed(() => store.state.portal)
    const fetchData = computed(() => store.state.fetchData)
    const wait = computed(() => store.state.wait)
    const { openMenu, drawer } = drawerHideClose()
    onMounted(() => {
      store.dispatch('setScreen', window.innerWidth)
      store.dispatch('setDrawer', drawer)
    })
    return {
      drawer,
      openMenu,
      portal,
      fetchData,
      wait
    }
  },
  created () {
    this.$store.dispatch('loadInformation')
  }
}
</script>
